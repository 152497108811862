@font-face {
  font-family: 'Ohanyan';
  src:  url('fonts/Ohanyan.eot?94cki3');
  src:  url('fonts/Ohanyan.eot?94cki3#iefix') format('embedded-opentype'),
    url('fonts/Ohanyan.woff2?94cki3') format('woff2'),
    url('fonts/Ohanyan.ttf?94cki3') format('truetype'),
    url('fonts/Ohanyan.woff?94cki3') format('woff'),
    url('fonts/Ohanyan.svg?94cki3#Ohanyan') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ov-"], [class*=" ov-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Ohanyan' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ov-play:before {
  content: "\e90f";
}
.ov-pause:before {
  content: "\e910";
}
.ov-twitter:before {
  content: "\e900";
}
.ov-instagram:before {
  content: "\e909";
}
.ov-facebook:before {
  content: "\e901";
}
.ov-copy:before {
  content: "\e90b";
}
.ov-time:before {
  content: "\e90c";
}
.ov-location:before {
  content: "\e90d";
}
.ov-web:before {
  content: "\e90e";
}
.ov-bottom-arrow:before {
  content: "\e90a";
}
.ov-arrow:before {
  content: "\e902";
}
.ov-close:before {
  content: "\e903";
}
.ov-burger-menu:before {
  content: "\e904";
}
.ov-collection:before {
  content: "\e905";
}
.ov-types:before {
  content: "\e906";
}
.ov-cocktail:before {
  content: "\e907";
}
.ov-product:before {
  content: "\e908";
}
